import React, { Component } from 'react';

import AsyncSelect from 'react-select/lib/Async';

export default class SearchableSelect extends Component {
  onChange = value => {
    console.log(value);
  };
  render() {
    let {
      className,
      promiseOptions,
      defaultOptions,
      onChange,
      ...rest
    } = this.props;
    return (
      <AsyncSelect
        classNamePrefix={className}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={onChange}
        className={`searchable-input ${className}`}
        classNamePrefix="searchable-input"
        {...rest}
      />
    );
  }
}
