import React from "react";
import DashboardNav from "../components/header/dashboardNav";
import UserStore from "../common/user-store";

import Notifications, { notify } from "react-notify-toast";

const Layout = ({ children }) => (
  <div id="wrapper">
    
    <div>
      {UserStore.loggedin() && <DashboardNav />}
      {children}
    </div>

    <Notifications />
  </div>
);

export default Layout;
