import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from '../shared/layout';
import UserStore from '../common/user-store';
import PatientLogin from '../common/patient-store';
import Async from 'react-code-splitting';
import Progress from 'react-progress-2';
import 'react-progress-2/main.css';

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      UserStore.loggedin() ? (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location },
          }}
        />
      ) : (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }
  />
);

// function getDashboardPage() {
//     let role = UserStore.get('UserType');
//     return role === Const.ROLE.SHIP_CAPTAIN ? '/dashboard-captain' : role === Const.ROLE.VENDOR ? '/dashboard-vendor' : role === Const.ROLE.ADMIN ? '/manage-users' : '/dashboard-admin';
// }

const Admin = (props) => (
  <Async load={import('../views/Admin/Admin')} componentProps={props} />
);
const DoctorInfo = (props) => (
  <Async load={import('../views/Admin/DoctorInfo')} componentProps={props} />
);
const AdminDashboard = (props) => (
  <Async
    load={import('../views/Admin/AdminDashboard')}
    componentProps={props}
  />
);
const AddFSO = (props) => (
  <Async load={import('../views/Admin/AddFSO')} componentProps={props} />
);

const AdminAllDocList = (props) => (
  <Async
    load={import('../views/Admin/AdminAllDocList')}
    componentProps={props}
  />
);
const AdminAllDocList2 = (props) => (
  <Async
    load={import('../views/Admin/AdminAllDocList2')}
    componentProps={props}
  />
);
const DoctorList = (props) => (
  <Async load={import('../views/Admin/DoctorList')} componentProps={props} />
);
const DoctorList2 = (props) => (
  <Async load={import('../views/Admin/DoctorList2')} componentProps={props} />
);
const FsoList = (props) => (
  <Async load={import('../views/Admin/FsoList')} componentProps={props} />
);
const DoctorAdd = (props) => (
  <Async load={import('../views/Admin/DoctorAdd')} componentProps={props} />
);
const AdminUpload = (props) => (
  <Async load={import('../views/Admin/AdminUpload')} componentProps={props} />
);
const BootUpload = (props) => (
  <Async load={import('../views/Admin/BootUpload')} componentProps={props} />
);
const FSOreg = (props) => (
  <Async load={import('../views/Admin/FSOreg')} componentProps={props} />
);
const OrigynMaster = (props) => (
  <Async load={import('../views/Admin/OrigynMaster')} componentProps={props} />
);
const Otp = (props) => (
  <Async load={import('../views/Admin/Otp')} componentProps={props} />
);
const Email = (props) => (
  <Async load={import('../views/Admin/Email')} componentProps={props} />
);
const Firstlast = (props) => (
  <Async load={import('../views/Admin/Firstlast')} componentProps={props} />
);
const Statecity = (props) => (
  <Async load={import('../views/Admin/Statecity')} componentProps={props} />
);
const landingpage = (props) => (
  <Async load={import('../views/Admin/Landingpage')} componentProps={props} />
);
const test = (props) => (
  <Async load={import('../views/Admin/test')} componentProps={props} />
);
const NewState = (props) => (
  <Async load={import('../views/Admin/Newstatecity')} componentProps={props} />
);
const PhoneStateCity = (props) => (
  <Async load={import('../views/Admin/Phonestatecity')} componentProps={props} />
);
const Updatestatecity = (props) => (
  <Async load={import('../views/Admin/Updatestatecity')} componentProps={props} />
);

// const MrDash = (props) => (
//   <Async load={import('../views/Admin/MrDash')} componentProps={props} />
// );

const routes = (
  <div>
    <Progress.Component thumbStyle={{ background: 'orange', height: '7px' }} />
    <Switch>
      <LoginRoute exact path='/Admin' component={Admin} />
      <Route path='/all-doctor-list' component={AdminAllDocList} />
      <Route path='/all-doctor-list2' component={AdminAllDocList2} />
      <Route path='/doctor-info/:docID' component={DoctorInfo} />
      <Route path='/add-fso' component={AddFSO} />
      <Route path='/admin-dashboard' component={AdminDashboard} />
      <Route path='/fso-list' component={FsoList} />
      <Route path='/FSOreg' component={FSOreg} />
      <Route path='/Otp' component={Otp} />
      <Route path='/Email' component={Email} />
      <Route path='/Firstlast' component={Firstlast} />
      <Route path='/Statecity' component={Statecity} />
      <Route path='/Newstatecity' component={NewState} />
      <Route path='/Phonestatecity' component={PhoneStateCity} />
      <Route path='/Updatestatecity' component={Updatestatecity} />

      <Route path='/' component={landingpage} />
      <Route path='/test' component={test} />
      <Route path='/Newstatecity' component={NewState} />
      

      <Route path='/doctor-list' component={DoctorList} />
      <Route path='/doctor-list2' component={DoctorList2} />
      <Route path='/mr-dashboard' component={DoctorList} />
      <Route path='/doctor-add' component={DoctorAdd} />
      <Route path='/admin-upload' component={AdminUpload} />
      <Route path='/OrigynMaster' component={OrigynMaster} />
      <Route path='/boot-upload' component={BootUpload} />
    </Switch> 
  </div>
);

export default routes;
