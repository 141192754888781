export default {
  API: {
    //  Url:'http://vytalconnectapi.adolpha.in/'

    // Url: 'https://apiconnect.vytal.in/',
    // Url: 'http://vytal.qovid19.com:3003/',
    // Url: 'https://connectdev.vytal.in:3003/',
    // Url: 'https://apidev.vkonnecthealth.com:3000',
    // Url: "https://api.vkonnecthealth.com:3000/",
    // Url: 'http://localhost:3000/dev/', //live 
    Url: 'https://h8zsvfo47h.execute-api.ap-south-1.amazonaws.com/dev/', //live http://localhost:3000/dev
    // Url: "https://apidev.vkonnecthealth.com:3000/",
    // https://apidev.vkonnecthealth.com:3000/
  },
  ROLE: {
    SYS_ADMIN: 0,
    DOCTOR_ADMIN: 1,
    ACCOUNTANT: 2,
    RECEPTIONIST: 3,
  },
};
