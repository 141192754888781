import { notify } from 'react-notify-toast';

export function validatePassword() {
  var p = document.getElementById('password').value,
    errors = [];
  if (p.length < 8) {
    errors.push('Your password must be at least 8 characters.');
  }
  if (p.search(/[a-z]/i) < 0) {
    errors.push('Your password must contain at least one letter.');
  }
  if (p.search(/[0-9]/) < 0) {
    errors.push('Your password must contain at least one digit.');
  }
  if (errors.length > 0) {
    notify.show(errors.join('\n'), 'error');
    return false;
  }
  return true;
}

export function emptyField() {
  let field = document.getElementsByClassName('emptyfield'),
    errors = [];
  let fieldsize = field.length;

  for (let i = 0; i < fieldsize; i++) {
    if (field[i].value === '') {
      notify.show('Some field is empty', 'error');
    }
  }
}

export function numbersOnly(event) {
  // handle paste
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    // handle enter
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
  }
  var regex = /[0-9]/;
  if (!regex.test(key)) {
    event.returnValue = false;
    event.preventDefault();
    return false;
  }
  return true;
}

export function textOnly(event) {
  // handle paste
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    // handle enter
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
  }
  var regex = /[a-zA-Z ]/;
  if (!regex.test(key)) {
    event.returnValue = false;
    event.preventDefault();
    return false;
  }
  return true;
}

export function numericOnly(event) {
  // handle paste
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    // handle enter
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
  }
  var regex = /^\d+(\.\d+)?$/;
  if (!regex.test(key)) {
    event.returnValue = false;
    event.preventDefault();
    return false;
  }
  return true;
}

export function decimalsOnly(event) {
  // handle paste
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');
  } else {
    // handle enter
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
  }
  var value = `${event.target.value}${key}`;
  var regex = /^[^.]*[0-9]|\./;
  if (
    !regex.test(value) ||
    value.split('.').length > 2 ||
    isNaN(value) ||
    parseFloat(value) === 0
  ) {
    event.returnValue = false;
    event.preventDefault();
    return false;
  }
  return true;
}

export function handleAppliationError(error) {
  if (error && error.response && error.response.status < 500) {
    notify.error(error.message, 'Application error');
  }
}

export function newGuid() {
  var d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function getUtcOffset() {
  return moment().utcOffset();
}

export function cloneObject(obj) {
  if (typeof obj === 'object') {
    return Object.assign({}, obj);
  }
  return obj;
}

export function cloneArray(arr) {
  if (typeof arr === 'object') {
    return Object.assign([], arr);
  }
  return arr;
}
