import { api } from '../common/service-config';

export function addPatient(payload) {
  return api().post(`/api/patient`, JSON.stringify(payload));
}
export function updatePatient(payload, lastSegment) {
  return api().put(`/api/patient/${lastSegment}`, JSON.stringify(payload));
}
export function bookAppointment(data) {
  // return api().post(`/api/appointment`, JSON.stringify(data));
  return api()({
    method: 'POST',
    url: '/api/appointment',
    data: data
  });
}
export function getPatient(lastSegment) {
  return api().get(`/api/patient/${lastSegment}`);
}
export function getAllPatient(params) {
  return api()({
    url: `/api/patient`,
    params
  });
}
export function setImage(payload, lastSegment) {
  return api().post(`/upload-profile/${lastSegment}`, JSON.stringify(payload));
}
export function getAppointment(lastSegment) {
  return api().get(`/api/appointment/${lastSegment}`);
}
export function getLabRecords(lastSegment) {
  return api().get(`/api/labrecords/${lastSegment}`);
}
export function getGynecLabRecords(lastSegment) {
  return api().get(`/api/gyneclabrecords/${lastSegment}`);
}
export function getAllAppointment(lastSegment) {
  return api().get(`/api/appointment/doctor/${lastSegment}`);
}
export function getExaminationdetails(lastSegment) {
  return api().get(`/api/examinationfindemrpatient/${lastSegment}`);
}

export function deletePatientById(ID) {
  return api().delete(`/api/patient/${ID}`);
}
export function getAllPatientPagination(pageLimit, page) {
  return api()({
    method: 'get',
    url: '/api/patient/pagination',
    params: {
      page: page,
      limit: pageLimit
    }
  });
}