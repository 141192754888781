
class Patient {
	set(data) {
        data.TokenExpiry = moment().add(10, 'hours').toDate(); // TODO: match the utc expiry
		var patient = JSON.stringify(data);
		window.localStorage.setItem("patient", patient);
	}

	get(prop) {
		let patient = window.localStorage.getItem("patient");
		if (patient !== null)
			return JSON.parse(patient)[prop];
		else
			return null;
	}

	let(key, value) {
		var patient = window.localStorage.getItem('patient');
		if (patient !== null) {
			var store = JSON.parse(patient);
			store[key] = value;
			window.localStorage.setItem('patient', JSON.stringify(store));
		}
	}

	loggedin() {
		if (this.get('TokenExpiry') == null) {
			return false;
		} else {
			if (moment().toDate() > moment(this.get('TokenExpiry')).toDate()) {
				window.localStorage.clear();
				return false;
			}
		}
		return true;
	}

	logout() {
		window.localStorage.clear();
		window.location.href = '/patientlogin';
	}
}

let patientStore = window.patientStore = new Patient;
export default patientStore;