import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-dom';
import routes from './routes/routes';

import './assets/css/authentication.css';
import './assets/css/blog.css';
import './assets/css/chatapp.css';
import './assets/css/adminScrollbar.css';
import './assets/css/rtl.css';
import './assets/css/timeline.css';
import './assets/css/main.css';
// import './assets/css/style.css';
import './assets/css/color_skins.css';
import './assets/css/custom.css';
import './assets/css/notification.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import 'sweetalert/dist/sweetalert.css';
import 'react-image-crop/dist/ReactCrop.css';

// import "./web.config";

render(<Router>{routes}</Router>, document.getElementById('app'));
