import axios from 'axios';
import Const from '../common/constants';
import UserStore from '../common/user-store';
import Notifications, { notify } from 'react-notify-toast';
import { getUtcOffset } from '../common/helper';

export function api() {
  let instance = axios.create({
    baseURL: Const.API.Url,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  //   instance.defaults.headers.common['Time-Offset'] = getUtcOffset();

  //   if (UserStore.loggedin()) {
  //     instance.defaults.headers.common['access-token'] = UserStore.get('token');
  //   }

  //   instance.interceptors.response.use(
  //     response => {
  //       return response;
  //     },
  //     error => {
  //       // TODO: handle auto-logout on 401
  //       if (error && error.response && error.response.status >= 500) {
  //         notify.error(`Error in service request: ${error.message}`);
  //         console.log(error);
  //       }
  //       return Promise.reject(error.response);
  //     }
  //   );
  //   return instance;
  // }

  // export function apiparts() {
  //   let instance = axios.create({
  //     baseURL: Const.API.Url,
  //     headers: { 'Content-Type': 'multipart/form-data' }
  //   });
  //   if (UserStore.loggedin()) {
  //     instance.defaults.headers.common['access-token'] = UserStore.get(
  //       'AccessToken'
  //     );
  //   }
  //   instance.interceptors.response.use(
  //     response => {
  //       return response;
  //     },
  //     error => {
  //       if (error && error.response && error.response.status >= 500) {
  //         notify.error(`Error in service request: ${error.message}`);
  //         console.log(error);
  //       }
  //       return Promise.reject(error.response);
  //     }
  //   );
  return instance;
}
